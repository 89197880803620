.comment-display-wrapper
  .card
  .manager-grade-selector
  .CircularProgressbar
  .CircularProgressbar-text {
  fill: #a6a6a6;
}

.circle-overlay {
  width: 50%;
  height: 50%;
  cursor: pointer;
}

.circle-overlay:hover {
  background: var(--primary-light);
  border-radius: 48%;
}

.manager-grade-selector.active .circle-overlay:hover {
  background: none;
  border-radius: 0%;
}

.manager-grade-selector .dropdown-menu {
  min-width: 0;
}

.manager-grade-selector .dropdown-menu.show {
  transform: translate(-9%, 75px) !important;
}
.CircularProgressbar .CircularProgressbar-text {
  font-size: 2.75rem;
  font-weight: 700;
  fill: var(--primary-dark);
}

.row-aligned {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding-right: 2rem;
}

.row-aligned p {
  margin-right: 5px;
  cursor: default;
}

.center-elemnt {
  margin: 0 auto !important;
}

.dropDownMenu {
  top: -5px;
  left: -35px;
  position: absolute;
}
