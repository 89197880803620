.CircularProgressbar .CircularProgressbar-text {
  font-size: 2.75rem;
  font-weight: 700;
  fill: var(--primary-dark);
}

.circle-overlay {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.grade-selector .dropdown {
  margin: auto;
}

.grade-selector .dropdown-menu.show {
  transform: translate(-38%, 75px) !important;
}

.grade-text {
  color: #000;
  cursor: pointer;
  margin: 0;
  text-align: center;
  position: relative;
}

.select-arrow::before {
  content: "";
  display: inline-block;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
  position: absolute;
  left: -15px;
  top: 9px;
}

.manager-select-arrow::before {
  content: "";
  display: inline-block;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
  position: absolute;
  left: -20px;
  top: 9px;
}

.grade-text.grayed-out {
  color: #c6c6c6;
}

.row-aligned {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.row-aligned p {
  margin-right: 5px;
  cursor: default;
}
